import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
// import AuthGuard from 'utils/route-guard/AuthGuard';
// import GuestGuard from 'utils/route-guard/GuestGuard';
import AuthGuard from 'utils/route-guard/AuthGuard';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
const UserProfile = Loadable(lazy(() => import('pages/account/UserProfile')));
const UserVia = Loadable(lazy(() => import('pages/account/UserVia')));
const UserAuth = Loadable(lazy(() => import('pages/account/UserAuth')));
const UserWork = Loadable(lazy(() => import('pages/account/UserWork')));
const UserSubscription = Loadable(lazy(() => import('pages/account/UserSubscription')));
const Pbod = Loadable(lazy(() => import('pages/pbod/Pbod')));
const Advisees = Loadable(lazy(() => import('pages/pbod/Advisees')));
const Surveys = Loadable(lazy(() => import('pages/appreciation/Surveys')));
const Appreciation = Loadable(lazy(() => import('pages/appreciation/Appreciation')));
const Testimonials = Loadable(lazy(() => import('pages/appreciation/Testimonials')));
const TestimonialRequest = Loadable(lazy(() => import('pages/appreciation/TestimonialRequest')));
const TestimonialRespond = Loadable(lazy(() => import('pages/appreciation/TestimonialRespond')));
const TestimonialReview = Loadable(lazy(() => import('pages/appreciation/TestimonialReview')));
const Quotes = Loadable(lazy(() => import('pages/appreciation/Quotes')));
const QuoteSend = Loadable(lazy(() => import('pages/appreciation/QuoteSend')));
const QuoteReview = Loadable(lazy(() => import('pages/appreciation/QuoteReview')));
const Kudos = Loadable(lazy(() => import('pages/appreciation/Kudos')));
const KudoSend = Loadable(lazy(() => import('pages/appreciation/KudoSend')));
const KudoReview = Loadable(lazy(() => import('pages/appreciation/KudoReview')));
const FeedbackDashboard = Loadable(lazy(() => import('pages/feedback/FeedbackDashboard')));
const MentorDashboard = Loadable(lazy(() => import('pages/pbod/MentorDashboard')));
const BuddyDashboard = Loadable(lazy(() => import('pages/pbod/BuddyDashboard')));
const AnonymousDashboard = Loadable(lazy(() => import('pages/pbod/Anonymous')));
const AnonymousRequest = Loadable(lazy(() => import('pages/pbod/AnonymousRequest')));
const AnonymousRespond = Loadable(lazy(() => import('pages/pbod/AnonymousRespond')));
const AnonymousReview = Loadable(lazy(() => import('pages/pbod/AnonymousReview')));
const Calendar = Loadable(lazy(() => import('pages/calendar/Calendar')));
const CoachDashboard = Loadable(lazy(() => import('pages/pbod/CoachDashboard')));
const SuggestionsDashboard = Loadable(lazy(() => import('pages/suggestions/SuggestionsDashboard')));
const CoacheeDashboard = Loadable(lazy(() => import('pages/pbod/CoacheeDashboard')));
const CoacheeAssignments = Loadable(lazy(() => import('pages/pbod/CoacheeAssignments')));
const MenteeDashboard = Loadable(lazy(() => import('pages/pbod/MenteeDashboard')));
const Contacts = Loadable(lazy(() => import('pages/contacts/Contacts')));
const Reports = Loadable(lazy(() => import('pages/reports/Reports')));
const OrgAdmin = Loadable(lazy(() => import('pages/reports/OrgAdmin')));
const ActivityHistory = Loadable(lazy(() => import('pages/activeHistory/ActiveHistory')));
const SuggestionRequest = Loadable(lazy(() => import('pages/suggestions/SuggestionRequest')));
const SuggestionRespond = Loadable(lazy(() => import('pages/suggestions/SuggestionRespond')));
const SuggestionReview = Loadable(lazy(() => import('pages/suggestions/SuggestionReview')));
const SuggAssignment = Loadable(lazy(() => import('pages/suggestions/SuggAssignment')));
const FeedbackRequest = Loadable(lazy(() => import('pages/feedback/FeedbackRequest')));
const FeedbackRespond = Loadable(lazy(() => import('pages/feedback/FeedbackRespond')));
const FeedbackReview = Loadable(lazy(() => import('pages/feedback/FeedbackReview')));
const FeedAssignment = Loadable(lazy(() => import('pages/feedback/FeedAssignment')));
const Software = Loadable(lazy(() => import('pages/extra-pages/Software')));
// const IndustryCard = Loadable(lazy(() => import('pages/newUser/IndustryCard')));
// const JobCharacteristicCard =  Loadable(lazy(() => import('pages/newUser/JobCharacteristicCard')));
// const NewUserCard = Loadable(lazy(() => import('pages/newUser/NewUserCard')));
// const DemographicInfoCard = Loadable(lazy(() => import('pages/newUser/DemographicInfoCard')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'Home', element: <SamplePage /> },
        { path: 'Pbod', element: <Pbod /> },
        { path: 'Advisees', element: <Advisees /> },
        { path: 'Surveys', element: <Surveys /> },
        { path: 'CoachDashboard', element: <CoachDashboard /> },
        { path: 'UserProfile', element: <UserProfile /> },
        { path: 'UserVia', element: <UserVia /> },
        { path: 'UserAuth', element: <UserAuth /> },
        { path: 'UserWork', element: <UserWork /> },
        { path: 'UserSubscription', element: <UserSubscription /> },
        { path: 'SuggestionsDashboard', element: <SuggestionsDashboard /> },
        { path: 'Appreciation', element: <Appreciation /> },
        { path: 'Testimonials', element: <Testimonials /> },
        { path: 'TestimonialRequest', element: <TestimonialRequest /> },
        { path: 'TestimonialRespond', element: <TestimonialRespond /> },
        { path: 'TestimonialReview', element: <TestimonialReview /> },
        { path: 'Quotes', element: <Quotes /> },
        { path: 'QuoteSend', element: <QuoteSend /> },
        { path: 'QuoteReview', element: <QuoteReview /> },
        { path: 'Kudos', element: <Kudos /> },
        { path: 'KudoSend', element: <KudoSend /> },
        { path: 'KudoReview', element: <KudoReview /> },
        { path: 'CoacheeDashboard', element: <CoacheeDashboard /> },
        { path: 'CoacheeAssignments', element: <CoacheeAssignments /> },
        { path: 'MenteeDashboard', element: <MenteeDashboard /> },
        { path: 'FeedbackDashboard', element: <FeedbackDashboard /> },
        { path: 'SuggestionRequest', element: <SuggestionRequest /> },
        { path: 'SuggestionRespond', element: <SuggestionRespond /> },
        { path: 'SuggestionReview', element: <SuggestionReview /> },
        { path: 'SuggAssignment', element: <SuggAssignment /> },
        { path: 'FeedbackRequest', element: <FeedbackRequest /> },
        { path: 'FeedbackRespond', element: <FeedbackRespond /> },
        { path: 'FeedbackReview', element: <FeedbackReview /> },
        { path: 'FeedAssignment', element: <FeedAssignment /> },
        { path: 'MentorDashboard', element: <MentorDashboard /> },
        { path: 'BuddyDashboard', element: <BuddyDashboard /> },
        { path: 'AnonymousDashboard', element: <AnonymousDashboard /> },
        { path: 'AnonymousRequest', element: <AnonymousRequest /> },
        { path: 'AnonymousRespond', element: <AnonymousRespond /> },
        { path: 'AnonymousReview', element: <AnonymousReview /> },
        { path: 'Calendar', element: <Calendar /> },
        { path: 'ActivityHistory', element: <ActivityHistory /> },
        { path: 'Contacts', element: <Contacts /> },
        { path: 'Reports', element: <Reports /> },
        { path: 'OrgAdmin', element: <OrgAdmin /> },
        { path: 'Software', element: <Software /> }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        { path: '404', element: <MaintenanceError /> },
        { path: '500', element: <MaintenanceError500 /> },
        { path: 'under-construction', element: <MaintenanceUnderConstruction /> },
        { path: 'coming-soon', element: <MaintenanceComingSoon /> }
      ]
    }
  ]
};

export default MainRoutes;
