// AvatarColorContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const AvatarColorContext = createContext();

export const useAvatarColor = () => useContext(AvatarColorContext);

export const AvatarColorProvider = ({ children }) => {
  const [avatarColor, setAvatarColor] = useState('#000000'); // Default color

  useEffect(() => {
    const fetchAvatarColor = async () => {
      try {
        const response = await axios.get('https://api.thrivesmart.com/getAvatarColor');
        setAvatarColor(response.data[0].setting_value);
      } catch (error) {
        console.error('Failed to fetch avatar color:', error);
      }
    };

    fetchAvatarColor();
  }, []);

  return <AvatarColorContext.Provider value={avatarColor}>{children}</AvatarColorContext.Provider>;
};
AvatarColorProvider.propTypes = {
  children: PropTypes.node
};
