// material-ui
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { ThemeMode } from 'config';
import logoPng from '../../assets/images/icons/logo.png';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 * import { ThemeMode } from 'config';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  // Check theme mode and set the appropriate logo
  const logoSrc = theme.palette.mode === ThemeMode.DARK ? logoPng : logoPng;

  return (
    <>
      <img src={logoSrc} alt="ThriveSmart Logo" width="40" height="40" style={{ marginTop: '20px' }} />
    </>
  );
};

LogoIcon.propTypes = {
  reverse: PropTypes.bool
};

export default LogoIcon;
