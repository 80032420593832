import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import AppStateContext from '../../AppStateContext'; // Adjust path accordingly

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const { currentUserState } = useContext(AppStateContext);

  useEffect(() => {
    if (!currentUserState || !currentUserState.user_id) {
      navigate('/', { replace: true, state: { from: window.location.pathname } });
    }
  }, [currentUserState, navigate]);

  return currentUserState && currentUserState.user_id ? children : null;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthGuard;
