import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Stack, Typography } from '@mui/material';
const currentYear = new Date().getFullYear();
const Footer = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px 0px', mt: 'auto' }}>
    <Typography variant="caption">&copy; {currentYear} ThriveSmart, Inc.</Typography>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
      <Link
        component={RouterLink}
        to="/Software"
        target="_blank"
        variant="caption"
        color="textPrimary"
        sx={{ display: { xs: 'none', sm: 'block' } }}
      >
        Software Info
      </Link>
      <Link component={RouterLink} to="https://thrivesmart.com/privacy/" target="_blank" variant="caption" color="textPrimary">
        Privacy
      </Link>
      <Link component={RouterLink} to="https://thrivesmart.com/terms/" target="_blank" variant="caption" color="textPrimary">
        Terms
      </Link>
    </Stack>
  </Stack>
);

export default Footer;
