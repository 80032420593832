import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import { useEffect, useContext } from 'react';
import AppStateContext from 'AppStateContext';
import { AvatarColorProvider } from 'contexts/AvatarColorContext';

// auth-provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  // Log a message when the App component mounts

  const { currentUserState } = useContext(AppStateContext);
  // const { newUserInfoState } = useContext(AppStateContext);
  // console.log(newUserInfoState);
  useEffect(() => {
    localStorage.setItem('currentUser', JSON.stringify(currentUserState));
  }, [currentUserState]);

  return (
    <AvatarColorProvider>
      <ThemeCustomization>
        {/* <RTLLayout> */}
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Notistack>
                  <Routes />
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
        {/* </RTLLayout> */}
      </ThemeCustomization>
    </AvatarColorProvider>
  );
};

export default App;
