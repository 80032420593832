import React, { useReducer } from 'react';
import AppStateContext from './AppStateContext';
import PropTypes from 'prop-types';

const currentUserReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return {
        ...state,
        ...action.payload
      };
    case 'CLEAR_CURRENT_USER':
      return {
        ...state,
        user_id: null,
        user_first_name: null,
        user_last_name: null,
        user_email1: null,
        user_demog_pronoun: null,
        user_color_bar: null,
        user_reports: null,
        user_pronoun_subjective: null,
        user_pronoun_objective: null,
        user_pronoun_possessive: null,
        user_org1: null,
        user_org2: null,
        user_org3: null,
        user_org4: null,
        user_org5: null,
        user_org_admin: null,
        user_image_url: null,
        user_tz: null,
        user_tz_name: null
      };
    default:
      return state;
  }
};

const initialNewUserState = {
  user_id: null,
  user_status: 1,
  user_terms: 0,
  user_first_name: null,
  user_last_name: null,
  user_email1: null,
  user_email2: null,
  user_password: null,
  user_datetime: new Date().toISOString().slice(0, 19).replace('T', ' '),
  user_job_title: null,
  user_company_name: null,
  user_demog_year: null,
  user_demog_race: null,
  user_demog_gender: null,
  user_demog_pronoun: null,
  user_job_function: null,
  user_job_level: null,
  user_industry1: null,
  user_industry2: null,
  user_industry3: null,
  user_color_bar: null,
  user_reports: null,
  user_pronoun_subjective: null,
  user_pronoun_objective: null,
  user_pronoun_possessive: null,
  user_org1: null,
  user_org2: null,
  user_org3: null,
  user_org4: null,
  user_org5: null,
  user_org_admin: null,
  user_image_url: null,
  user_invite: false,
  user_tz: null,
  user_tz_name: null
};

const newUserInfoReducer = (state = initialNewUserState, action) => {
  switch (action.type) {
    case 'SET_NEW_USER_INFORMATION':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const AppStateProvider = ({ children }) => {
  // console.log('AppStateProvider: ' + typeof children, children);
  // const initialSuggestionsState = { value: null };

  const thriverInfo = localStorage.getItem('currentUser');
  const initialCurrentUserState = thriverInfo ? JSON.parse(thriverInfo) : null;

  const [newUserInfoState, newUserInfoDispatch] = useReducer(newUserInfoReducer, initialNewUserState);
  const [currentUserState, currentUserDispatch] = useReducer(currentUserReducer, initialCurrentUserState);

  return (
    <AppStateContext.Provider
      value={{
        currentUserState,
        currentUserDispatch,
        newUserInfoState,
        newUserInfoDispatch
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
AppStateProvider.propTypes = {
  children: PropTypes.node
};
