import { lazy } from 'react';

// project import
import GuestGuard from 'utils/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const Start = Loadable(lazy(() => import('pages/auth/Start')));
const NewUserInfo = Loadable(lazy(() => import('pages/newUser/NewUserInfo')));
const NewUserJobInfo = Loadable(lazy(() => import('pages/newUser/NewUserJobInfo')));
const NewUserWorkInfo = Loadable(lazy(() => import('pages/newUser/NewUserWorkInfo')));
const NewUserConfirm = Loadable(lazy(() => import('pages/newUser/NewUserConfirm')));
const ForgotPassword = Loadable(lazy(() => import('pages/auth/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')));
// Testimonial is a non-user responding to a request for a testimonial
const Testimonial = Loadable(lazy(() => import('pages/appreciation/Testimonial')));
// Respond is a non-user responding to a request for Suggestion or Feedback
const Respond = Loadable(lazy(() => import('pages/newUser/Respond')));
// AnonymousResponse is a non-user responding to a request for Anonymous Peer Feedback
const AnonymousResponse = Loadable(lazy(() => import('pages/pbod/AnonymousResponse')));
// Invite is a non-user responding to a request for a touch schedule (Coach, Mentor, Buddy)
const Invite = Loadable(lazy(() => import('pages/pbod/Invite')));
// const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
// const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));
// const NewUserCard = Loadable(lazy(() => import('pages/newUser/NewUserCard')));
// const DemographicInfoCard = Loadable(lazy(() => import('pages/newUser/DemographicInfoCard')));
// const JobCharacteristicCard = Loadable(lazy(() => import('pages/newUser/JobCharacteristicCard')));
// const IndustryCard = Loadable(lazy(() => import('pages/newUser/IndustryCard')));
// const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        { path: '/', element: <AuthLogin /> },
        { path: 'Start', element: <Start /> },
        { path: 'NewUserInfo', element: <NewUserInfo /> },
        { path: 'NewUserJobInfo', element: <NewUserJobInfo /> },
        { path: 'NewUserWorkInfo', element: <NewUserWorkInfo /> },
        { path: 'NewUserConfirm', element: <NewUserConfirm /> },
        { path: 'ForgotPassword', element: <ForgotPassword /> },
        { path: 'ResetPassword', element: <ResetPassword /> },
        { path: 'Testimonial', element: <Testimonial /> },
        { path: 'Respond', element: <Respond /> },
        { path: 'AnonymousResponse', element: <AnonymousResponse /> },
        { path: 'Invite', element: <Invite /> }
        // { path: 'check-mail', element: <AuthCheckMail /> },
        // { path: 'register', element: <AuthRegister /> },
        // { path: 'code-verification', element: <AuthCodeVerification /> },
        // { path: 'Industry', element: <IndustryCard /> },
        // { path: 'login', element: <NewUserCard /> },
        // { path: 'Demographics', element: <DemographicInfoCard /> },
        // { path: 'JobCharacteristics', element: <JobCharacteristicCard /> }
      ]
    }
  ]
};

export default LoginRoutes;
