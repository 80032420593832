/* eslint-disable no-unused-vars */
import { useLayoutEffect, useState, useContext, useEffect, useRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import AppStateContext from '../../../../../AppStateContext';
import {
  BorderOutlined,
  BoxPlotOutlined,
  HomeOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  SmileOutlined,
  StopOutlined
} from '@ant-design/icons';
import Badge from '@mui/material/Badge';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'; // icons
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { HORIZONTAL_MAX_ITEM, MenuOrientation } from 'config';
import { useSelector } from 'store';
import useConfig from 'hooks/useConfig';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

const icons = {
  CalendarMonthIcon,
  RestoreOutlinedIcon,
  PeopleAltOutlinedIcon,
  NotificationsActiveOutlinedIcon,
  HomeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined
};

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const theme = useTheme();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { currentUserState } = useContext(AppStateContext);
  const [selectedItems, setSelectedItems] = useState('');
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [menuItems, setMenuItems] = useState({ items: [] });
  const badgeValueRef = useRef(null);

  const userData = JSON.parse(localStorage.getItem('currentUser'));

  // useEffect(()=>{fetch(`/assignments?number=${currentUserState.user_id}`)
  // .then((response) => response.json())
  // .then((data) => {
  //   badgeValueRef.current = data.length;
  // })
  // .catch((error) => {
  //   console.error('There was an error!', error);
  // })},[menuItem])

  // const nestedMap = {
  //   items: [
  //     {
  //       id: 'other',
  //       type: 'group',
  //       children: [
  //         {
  //           id: 'home',
  //           title: <FormattedMessage id="Home" />,
  //           type: 'item',
  //           url: '/home',
  //           icon: icons.HomeOutlined
  //         },
  //         {
  //           id: 'documentation',
  //           title: <FormattedMessage id="Calendar" />,
  //           type: 'item',
  //           url: '/Calendar',
  //           icon: icons.CalendarMonthIcon
  //         },
  //         {
  //           id: 'contacts',
  //           title: <FormattedMessage id="Contacts" />,
  //           type: 'item',
  //           url: '/Contacts',
  //           icon: icons.PeopleAltOutlinedIcon
  //         },
  //         {
  //           id: 'history',
  //           title: <FormattedMessage id="Activity History" />,
  //           type: 'item',
  //           url: '/History',
  //           icon: icons.RestoreOutlinedIcon
  //         },
  //         {
  //           id: 'tasks',
  //           title: <FormattedMessage id="Tasks" />,
  //           type: 'item',
  //           url: '/Tasks',
  //           icon: () => (
  //             <Badge
  //               badgeContent={badgeValueRef.current }
  //               color="error"
  //               anchorOrigin={{
  //                 vertical: 'top',
  //                 horizontal: 'left'
  //               }}
  //             >
  //               <AssignmentIcon fontSize="small" />
  //             </Badge>
  //           )
  //         }
  //       ]
  //     }
  //   ]
  // };

  useLayoutEffect(() => {
    setMenuItems(menuItem);
    // eslint-disable-next-line
  }, [menuItem]);

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItems.items.length - 1;
  let remItems = [];
  let lastItemId;

  //  first it checks menu item is more than giving HORIZONTAL_MAX_ITEM after that get lastItemid by giving horizontal max
  // item and it sets horizontal menu by giving horizontal max item lastly slice menuItem from array and set into remItems

  if (lastItem && lastItem < menuItems.items.length) {
    lastItemId = menuItems.items[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    remItems = menuItems.items.slice(lastItem - 1, menuItems.items.length).map((item) => ({
      title: item.title,
      elements: item.children,
      icon: item.icon
    }));
  }

  const navGroups = menuItems.items.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            key={item.id}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
            lastItem={lastItem}
            remItems={remItems}
            lastItemId={lastItemId}
            item={item}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        '& > ul:first-of-type': { mt: 0 },
        display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block',
        mt: isHorizontal ? 0 : 3
      }}
    >
      {navGroups}
    </Box>
  );
};

export default Navigation;
