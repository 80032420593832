import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { ThemeMode } from 'config';
import logoPng from '../../assets/images/icons/logo.png';

const LogoMain = () => {
  const theme = useTheme();
  // Check theme mode and set the appropriate logo
  const logoSrc = theme.palette.mode === ThemeMode.DARK ? logoPng : logoPng;
  return (
    <>
      <img src={logoSrc} alt="ThriveSmart" width="85" height="85" style={{ marginTop: '40px', marginLeft: '20px' }} />
    </>
  );
};
LogoMain.propTypes = {
  reverse: PropTypes.bool
};
export default LogoMain;
