import { FormattedMessage } from 'react-intl';
import React from 'react';
import {
  BorderOutlined,
  BoxPlotOutlined,
  HomeOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  SmileOutlined,
  StopOutlined
} from '@ant-design/icons';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const icons = {
  CalendarMonthIcon,
  RestoreOutlinedIcon,
  PeopleAltOutlinedIcon,
  NotificationsActiveOutlinedIcon,
  HomeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  AssessmentIcon,
  AdminPanelSettingsIcon
};

// ==========================|| MENU ITEMS - SUPPORT ||======================= //

const userDataString = localStorage.getItem('currentUser');
const userData = userDataString ? JSON.parse(userDataString) : null;

// const userReports = userData ? userData.user_reports : 0;
const userOrgAdmin = userData ? userData.user_org_admin : 0;
const userReports = 1;
// console.log('userReports: ' + userReports);
// console.log('/menu-items/other.js : userOrgAdmin: ' + userOrgAdmin);

// Define the 'Reports' menu item
const reportsMenuItem =
  userReports === 1
    ? {
        id: 'reports',
        title: <FormattedMessage id="Reports" />,
        type: 'item',
        url: '/Reports',
        icon: icons.AssessmentIcon
      }
    : null;

// Define the 'Org Admin' menu item
const orgAdminMenuItem =
  userOrgAdmin !== 0
    ? {
        id: 'orgAdmin',
        title: <FormattedMessage id="OrgAdmin" />,
        type: 'item',
        url: '/OrgAdmin',
        icon: icons.AdminPanelSettingsIcon
      }
    : null;

const other = {
  id: 'other',
  type: 'group',
  children: [
    {
      id: 'home',
      title: <FormattedMessage id="Home" />,
      type: 'item',
      url: '/home',
      icon: icons.HomeOutlined
    },
    {
      id: 'documentation',
      title: <FormattedMessage id="Calendar" />,
      type: 'item',
      url: '/Calendar',
      icon: icons.CalendarMonthIcon
    },
    {
      id: 'contacts',
      title: <FormattedMessage id="Contacts" />,
      type: 'item',
      url: '/Contacts',
      icon: icons.PeopleAltOutlinedIcon
    },
    reportsMenuItem,
    orgAdminMenuItem
  ].filter(Boolean) // This filter removes null values from array
};

export default other;
